.pinlocation_centermarker {
  position: absolute;
  background: url('../../assets/images/markerpin.png') no-repeat;
  top: 50%;
  left: 50%;
  z-index: 1;
  height: 42px;
  width: 20px;
  cursor: pointer;
  margin-top: -58px;
  margin-left: -14px;
  background-size: 100% 100%;
  background-position: center;
}
